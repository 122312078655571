import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {Col} from 'jsxstyle';

import Layout from '@layouts';
import {BLUE, WHITE} from '@styles/colors';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default () => {
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{'Privacy Policy'}</title>
        <meta name="description" content={'Privacy Policy'} />
      </Helmet>
      <Layout menuItemColor={BLUE} menuBackground={WHITE}>
        <Col
          width="90%"
          margin="0 auto"
          alignItems="flex-start"
          mediaQueries={mediaQueries}
          smMargin="100px 0"
        >
          <Headline margin="200px 0 40px 0" textAlign="left">
            PRIVACY POLICY
          </Headline>
          <Paragraph textAlign="left">
            This Privacy Policy covers the information practices of websites
            that link to this Privacy Policy (each referred to as a “Site”).
            Fetner Properties, Inc. (“Fetner”) believes strongly in protecting
            your privacy and the confidentiality of your Personal Information.
            We acknowledge that you may have privacy and security concerns with
            respect to the information we collect, use, and disclose to third
            parties. As a result, we have developed this Privacy Policy.
          </Paragraph>
          <Paragraph textAlign="left">
            As used in this Policy, Personal Information is any combination of
            information, in the possession of or likely to come into the
            possession of Fetner, that can be used to identify, contact, or
            locate a discrete individual (“Personal Information”) and will be
            treated in accordance with this Privacy Policy. Any information
            which cannot be used to identify a discrete individual (such as
            aggregated statistical information) is not Personal Information.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            PURPOSE FOR COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION
          </Headline>
          <Paragraph textAlign="left">
            Fetner collects, uses and discloses your Personal Information in its
            normal course of business for the following purposes:
          </Paragraph>
          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>Establishing and maintaining communications with you;</li>
            <li>
              Where you have requested information from Fetner, assisting you in
              the completion of your request and the assessment of your
              eligibility for any such request;
            </li>
            <li>
              Responding to your inquiries about our properties or services;
            </li>
            <li>
              Determining your interests based on your internet navigation;
            </li>
            <li>
              Meeting legal, security, processing and regulatory requirements;
            </li>
            <li>
              Protecting against fraud, suspicious or other illegal activities;
              and
            </li>
            <li>
              Compiling statistics for analysis of our sites and our business.
            </li>
          </ul>
          <Headline margin="40px 0" textAlign="left">
            WHAT INFORMATION WE COLLECT
          </Headline>
          <Paragraph textAlign="left">
            The information gathered by Fetner from this Site falls into two
            categories: (1) information voluntarily supplied by visitors to our
            Site and (2) tracking information gathered as visitors navigate
            through our Site.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Information voluntarily provided by you
          </Headline>
          <Paragraph textAlign="left">
            When using this Site, you may choose to provide us with information
            to help us serve your needs. The Personal Information that we
            collect will depend on how you choose to use this Site.
          </Paragraph>
          <Paragraph textAlign="left">
            These Terms of Use permit you to use a Website for your personal,
            non-commercial use only. You must not reproduce, distribute, modify,
            create derivative works of, publicly display, publicly perform,
            republish, download, store or transmit any of the material on a
            Website, except as follows:
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Prohibited Uses
          </Headline>
          <Paragraph textAlign="left">
            You may use a Website only for lawful purposes and in accordance
            with these Terms of Use. You agree not to use a Website:
          </Paragraph>
          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>Where you request information or contact us directly</li>
          </ul>
          <Paragraph textAlign="left">
            If you use the “contact” feature of a Site - either to inquire about
            our properties, or inquire about further information from us - we
            need you to submit your name and e-mail address. You may also submit
            other necessary information as part of your message to us so we may
            send you the information you have requested.
          </Paragraph>

          <Headline margin="40px 0" textAlign="left">
            Website Navigational Information
          </Headline>
          <Paragraph textAlign="left">
            As you navigate the Site, we may also collect information through
            the use of commonly-used information-gathering tools, such as
            cookies and web beacons (collectively “Website Navigational
            Information”). Website Navigational Information includes standard
            information from your web browser (such as browser type and browser
            language), your Internet Protocol (“IP”) address, and the actions
            you take on the Site (such as the web pages viewed and the links
            clicked).
          </Paragraph>

          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>Cookies</li>
          </ul>

          <Paragraph textAlign="left">
            Like many companies, we may use cookies on this Site. Cookies are
            pieces of information shared between your web browser and a website.
            Use of cookies enables a faster and easier experience for the user.
            A cookie cannot read data off your computer's hard drive. There are
            different kinds of cookies with different functions:
          </Paragraph>
          <Paragraph textAlign="left">
            Session cookies: these are only stored on your computer during your
            web session. They are automatically deleted when the browser is
            closed. They usually store an anonymous session ID allowing you to
            browse a website without having to log in to each page. They do not
            collect any information from your computer.
          </Paragraph>
          <Paragraph textAlign="left">
            Persistent cookies: a persistent cookie is one stored as a file on
            your computer, and it remains there when you close your web browser.
            The cookie can be read by the website that created it when you visit
            that website again.
          </Paragraph>
          <Paragraph textAlign="left">
            First-party cookies: the function of this type of cookie is to
            retain your preferences for a particular website for the entity that
            owns that website. They are stored and sent between Fetner’s servers
            and your computer’s hard drive. They are not used for anything other
            than for personalization as set by you. These cookies may be either
            Session or Persistent cookies.
          </Paragraph>
          <Paragraph textAlign="left">
            Third-party cookies: the function of this type of cookie is to
            retain your interaction with a particular website for an entity that
            does not own that website. They are stored and sent between the
            Third-party’s server and your computer’s hard drive. These cookies
            are usually Persistent cookies.
          </Paragraph>
          <Paragraph textAlign="left">
            This Site uses a number of web analytics and advertising services
            (such as those provided by Google). These analytics and advertising
            providers use cookies to help the website analyze how users use the
            site. The information generated by the cookie about your use of the
            website (including your IP address) will be transmitted to and
            stored on servers in the United States. These providers will use
            this information for the purpose of evaluating your use of the
            website, compiling reports on website activity, and providing other
            services relating to website activity, internet usage, and your
            interests as determined by your website navigation for Fetner and
            its affiliates. These providers may also transfer this information
            to third parties where required to do so by law, or where such third
            parties process the information on the provider's behalf. You may
            refuse the use of cookies by selecting the appropriate settings on
            your browser, however please note that if you do this you may not be
            able to use the full functionality of this website. By using this
            website, you consent to the processing of data about you in the
            manner and for the purposes set out above.
          </Paragraph>
          <Paragraph textAlign="left">
            The major browsers have attempted to implement the draft “Do Not
            Track” (“DNT”) standard of the World Wide Web Consortium (“W3C”) in
            their latest releases. As this standard has not been finalized,
            Fetner’s Sites are not compatible with DNT.
          </Paragraph>
          <Paragraph textAlign="left">
            For information on all of these categories of cookies, and for more
            information generally on cookies please refer to
            www.aboutcookies.org. We use generally cookies as follows:
            Functionality These cookies allow our Site to remember choices you
            make (such as your user name, language or the region you are in) and
            provide enhanced features. For instance, a Site may be able to
            remember your log in details, so that you do not have to repeatedly
            sign in to your account when using a particular device to access our
            Site. These cookies can also be used to remember changes you have
            made to text size, font and other parts of web pages that you can
            customize. They may also be used to provide services you have
            requested such as viewing a video or commenting on an article. The
            information these cookies collect is usually anonymized. They do not
            gather any information about you that could be used for advertising
            or remember where you have been on the internet. Performance These
            cookies collect information about how visitors use a Site, for
            instance which pages visitors go to most often, and if they get
            error messages from web pages. They also allow us to record and
            count the number of visitors to the Site, all of which enables us to
            see how visitors use the Site in order to improve the way that our
            Site works. The information these cookies collect is normally
            anonymous and is used to improve how our Site works. However, some
            of these cookies may include Personal Information. Please consult
            your web browser's ‘Help’ documentation or visit
            www.aboutcookies.org. for more information about how to turn cookies
            on and off for your browser. Marketing These cookies are used to
            deliver content relevant to your interests on our Site and third
            party sites based on how you interact with our advertisements or
            content.
          </Paragraph>

          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>Web Beacons</li>
          </ul>

          <Paragraph textAlign="left">
            The Site may also use web beacons (including web beacons supplied or
            provided by third parties) alone or in conjunction with cookies to
            compile information about users’ usage of the Site and interaction
            with e-mails from Fetner. Web beacons are clear electronic images
            that can recognize certain types of information on your computer,
            such as cookies, when you viewed a particular Site tied to the web
            beacon, and a description of a Site tied to the web beacon. We use
            web beacons to operate and improve the Sites and e-mail
            communications. We may use information from web beacons in
            combination with other data we have about our clients to provide you
            with information about Fetner and our services. We will conduct this
            review on an anonymous basis.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            DISCLOSURE OF INFORMATION TO OTHERS
          </Headline>

          <Paragraph textAlign="left">
            We do not disclose any Personal Information about you to third
            parties except as stated in this Privacy Policy, as otherwise
            permitted by law, or authorized by you.
          </Paragraph>
          <Paragraph textAlign="left">
            Third parties to whom we generally disclose information are required
            by law or a contract with us, to keep your Personal Information
            confidential and secure, and to use and disclose it for purposes
            that a reasonable person would consider appropriate in the
            circumstances and generally for purposes as follows:{' '}
          </Paragraph>
          <ul
            style={{
              fontFamily: 'proxima-nova',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: BLUE,
              listStyle: 'inside disc',
              marginBottom: '40px',
            }}
          >
            <li>
              To provide the information and services you have requested from
              us;
            </li>
            <li>To provide content based on your interests; or</li>
            <li>
              To notify you or allow our affiliated companies to notify you of
              certain products or services offered by our affiliated companies.
            </li>
          </ul>
          <Headline margin="40px 0" textAlign="left">
            Affiliate Sharing
          </Headline>
          <Paragraph textAlign="left">
            In the normal course of performing services for our clients,
            Personal Information may be shared within Fetner and its affiliates
            for research and statistical purposes, system administration, joint
            marketing, providing you services or information, and crime
            prevention or detection.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Service Providers
          </Headline>
          <Paragraph textAlign="left">
            Because a number of the service providers we use are located in the
            United States, including certain Fetner affiliates, your Personal
            Information may be processed and stored inside the United States,
            and the U.S. government, courts, or law enforcement or regulatory
            agencies may be able to obtain disclosure of your Personal
            Information under US laws.
          </Paragraph>
          <Paragraph textAlign="left">
            Fetner's service suppliers adhere to the same protections regarding
            the collection, use, and retention of data as we do.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Business Transactions
          </Headline>
          <Paragraph textAlign="left">
            As we continue to develop our business, we might sell or buy assets.
            In such transactions, user information, including Personal
            Information, generally is one of the transferred business assets.
            Also, if either Fetner itself or substantially all of Fetner assets
            were acquired, your Personal Information may be one of the
            transferred assets. Therefore, we may disclose and/or transfer your
            Personal Information to a third party purchaser in these
            circumstances
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            Other Legally Required Disclosures
          </Headline>
          <Paragraph textAlign="left">
            Fetner preserves the right to disclose without your prior permission
            any Personal Information about you or your use of this Site if
            Fetner has a good faith belief that such action is necessary to: (a)
            protect and defend the rights, property or safety of Fetner,
            Fetner’s employees, other users of this Site, or the public; (b)
            enforce the terms and conditions that apply to use of this Site; (c)
            as required by a legally valid request from a competent governmental
            authority; or (d) respond to claims that any content violates the
            rights of third-parties. We may also disclose Personal Information
            as we deem necessary to satisfy any applicable law, regulation,
            legal process, or governmental request
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            SAFEGUARDS
          </Headline>
          <Paragraph textAlign="left">
            We have in place reasonable safeguards appropriate to the
            sensitivity of the information we maintain. Safeguards will vary
            depending on the sensitivity, format, location, amount, distribution
            and storage of the Personal Information. They include physical,
            technical, and managerial measures to keep Personal Information
            protected from unauthorized access. Note that due to the nature of
            the Internet and related technology, we cannot absolutely guarantee
            the security of Personal Information disclosed over the Internet,
            and Fetner expressly disclaims any such obligation.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            EXTERNAL LINKS
          </Headline>
          <Paragraph textAlign="left">
            Fetner Sites may include links to other websites, such as Facebook,
            whose privacy policies we do not control. Once you leave our
            websites, use of any Personal Information you provide is governed by
            the privacy policy of the operator of the website you are visiting.
            That policy may differ from ours.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            ACCURACY
          </Headline>
          <Paragraph textAlign="left">
            Our knowing about changes to your Personal Information (i.e. your
            email address or phone number) may be key to effectively
            communicating with you at your request. If any of your details
            change you can update us by e-mailing us at INFO@Fetner.com. Please
            keep us informed of changes to your Personal Information.
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            QUESTIONS
          </Headline>
          <Paragraph textAlign="left">
            If you have any questions or concerns about how your Personal
            Information is handled by Fetner, please let us know by sending an
            email or writing to us at:
          </Paragraph>
          <Paragraph textAlign="left" margin="0 0 40px 0">
            Fetner Properties, LLC 780 Third Avenue, Suite 401 New York, NY
            10017
          </Paragraph>
          <Headline margin="40px 0" textAlign="left">
            CHANGES TO THIS PRIVACY POLICY
          </Headline>
          <Paragraph textAlign="left" margin="0 0 40px 0">
            If there is any material change to your rights under this Privacy
            Policy, Fetner will provide notice of such change. As part of this
            notice, Fetner may post a notice of the change on the site in a
            clear and conspicuous manner. Fetner may also communicate the change
            via email or postal mail if this is the way that Fetner normally
            corresponds with you.
          </Paragraph>
        </Col>
      </Layout>
    </Fragment>
  );
};
